<template>
  <Loading v-if="initLoading" />
  <div v-else>
    <Header
      @click="
        $router.back();
        $store.commit('REMOVE_NEEDGOODS');
      "
      callBack
      >合成-{{ data.goods.name }}</Header
    >
    <div class="head">
      <img :src="data.goods.showPic" alt="" />
    </div>
    <div class="content">
      <div class="title">{{ data.goods.name }}</div>
      <div class="tag">
        <div class="left">单次合成</div>
        <div class="right">{{ data.batchNum || 0 }}份</div>
      </div>
      <div class="time">合成时间: {{ data.beginTime }}-{{ data.endTime }}</div>
      <div class="line"></div>
      <div class="science">请选择{{ data.batchNum }}套进行合成的藏品材料：</div>
      <div class="material">
        <div style="white-space: nowrap">
          <div
            class="item"
            v-for="item in data.haveGoodList"
            :key="item.kid"
            @click="
              $router.push(
                `/selectScience?id=${item.id}&destory=${item.destory}&number=${
                  item.number
                }&checkGoods=${item.checkGoods ? 1 : 0}&kid=${item.kid}`
              )
            "
          >
            <img :src="item.listPic" alt="" />
            <div class="mask" v-if="total(item.kid) != item.number"></div>
            <div class="noSelect ico" v-if="!total(item.kid)">
              x{{ item.number }}
            </div>
            <div
              class="ok ico"
              v-else-if="total(item.kid) == item.number"
            ></div>
            <div class="noOk ico" v-else>
              {{ total(item.kid) - item.number }}
            </div>
          </div>
        </div>
      </div>
      <div class="info">
        集齐以上{{ data.haveGoodList.length || 0 }}款藏品可进行合成
      </div>
      <!-- <div class="key" v-if="data.haveQ != 0">
        <div class="left">
          <img :src="data.keyList.listPic" alt="" />
        </div>
        <div class="middle">
          <div class="title">{{ data.keyList.name }}</div>
          <div class="subTitle">
            合成藏品需消耗<span style="color: #333; font-weight: 600"
              >{{ data.haveQ || 0 }}个</span
            >{{ data.keyList.name }}
          </div>
        </div>
      </div> -->
      <div class="describe" v-html="data.goods.describe"></div>
      <div class="btn">
        <div v-if="data.is_allow == 1">您已达合成上限</div>
        <div v-else-if="data.is_allow == 2">来晚了一步，已无合成商品</div>
        <div v-else-if="data.is_open == 1">未到合成时间</div>
        <div v-else-if="data.is_open == 2">已结束</div>
        <div v-else-if="isSelect">请选择所需藏品材料</div>
        <div v-else-if="!isOk">所选的材料不足，无法合成</div>
        <div v-else class="ok" @click="isShowReminder = true">立即合成</div>
      </div>
    </div>

    <div class="tips" v-if="isShowReminder">
      <div class="box">
        <div class="title">温馨提示</div>
        <div class="info">此操作将会销毁您拥有的相应藏品材料，请谨慎操作！</div>
        <div class="btn">
          <div class="left" @click="isShowReminder = false">取消</div>
          <div class="right" @click="userRunCompose">确定</div>
        </div>
      </div>
    </div>
    <div class="tips" v-if="isShowTip">
      <div class="box">
        <div class="title">合成成功</div>
        <div class="info">{{ msg || "恭喜您合成成功，快去查看吧！" }}</div>
        <div class="btn">
          <div class="left" @click="close">取消</div>
          <div class="right" @click="$router.replace('/user')">去查看</div>
        </div>
      </div>
    </div>
    <div class="tips" v-if="isShowTip1">
      <div class="box">
        <div class="title">合成失败</div>
        <div class="info">{{ msg || "很遗憾，合成失败" }}</div>
        <div class="btn">
          <!-- <div class="left" @click="close">取消</div> -->
          <div class="right" style="margin: 0 auto" @click="close">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      initLoading: true,
      data: { goods: {}, haveGoodList: [] },
      isShowReminder: false,
      isShowTip: false,
      isShowTip1: false,
      msg: "",
    };
  },
  computed: {
    isOk() {
      let ok = true;
      this.data.haveGoodList.forEach((e) => {
        if ((this.total(e.kid) || 0) < e.number) {
          ok = false;
        }
      });
      return ok;
    },
    isSelect() {
      if (Object.keys(this.$store.state.needGoods).length > 0) {
        let isSel = true;
        Object.keys(this.$store.state.needGoods).forEach((e) => {
          if (this.$store.state.needGoods[e].total > 0) {
            isSel = false;
          }
        });
        return isSel;
      }
      return true;
    },
  },
  mounted() {
    this.initLoading = true;
    this.getGoodsComposeById();
  },
  methods: {
    total(id) {
      if (this.$store.state.needGoods[id]) {
        return this.$store.state.needGoods[id].total;
      } else {
        return 0;
      }
    },
    // 合成接口
    async userRunCompose() {
      try {
        const list = [];
        Object.entries(this.$store.state.needGoods).forEach(([v, k]) => {
          list.push({
            kid: v,
            childId: k.id.join(","),
          });
        });
        const query = {
          id: this.$route.query.id,
          checkList: JSON.stringify(list),
          token: localStorage.getItem("token"),
        };
        if (this.data.checkGoodsList && this.data.checkGoodsList.id) {
          const id = this.data.checkGoodsList.id;
          const idList = this.$store.state.needGoods[id];
          if (this.$store.state.needGoods && idList) {
            query.checkList = idList.id.join(",");
            const newList = list.filter((e) => e.id != id);
            query.list = JSON.stringify(newList);
          }
        }
        const data = await this.$api.userRunbatchCompose(query);
        this.msg = data.msg;
        if (data.code === 0) {
          this.isShowTip = true;
          // this.$router.replace('/mergeRecord');
        } else if (data.code == "10003") {
          this.isShowTip1 = true;
        } else {
          return this.$toast(data.msg);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isShowReminder = false;
      }
    },
    async getGoodsComposeById() {
      try {
        const data = await this.$api.goodsbatchComposeById({
          id: this.$route.query.id,
          token: localStorage.getItem("token"),
        });
        if (data.code === 0) {
          this.data = data.data || { goods: {}, haveGoodList: [] };
          if (this.data.checkGoodsList.length) {
            this.data.checkGoodsList.forEach((e) => (e.checkGoods = true));
            this.data.haveGoodList.push(...this.data.checkGoodsList);
          }
        } else {
          return this.$toast(data.msg);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.initLoading = false;
      }
    },
    close() {
      window.location.reload();
    },
  },
};
</script>

<style>
.describe img {
  max-width: 100%;
}
</style>
<style lang="less" scoped>
.head {
  img {
    width: 100%;
  }
}
.tips {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    padding: 20px;
    box-sizing: border-box;
    width: 282px;
    height: 170px;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: 15px;
      text-align: center;
      font-weight: 600;
      color: #000;
    }
    .info {
      font-size: 14px;
      color: #666;
      text-align: center;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        width: 80px;
        height: 28px;
        background: #0754d3;
        border-radius: 3px;
        border: 1px solid #0754d3;
        color: #fff;
        &.left {
          color: #0754d3;
          border: 1px solid #0754d3;
          background: transparent;
        }
      }
    }
  }
}
.content {
  padding: 10px 20px 60px;
  .title {
    font-size: 14px;
    font-weight: 600;
  }
  .btn {
    position: fixed;
    padding: 10px 20px;
    background: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    div {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 8px 0;
      border-radius: 3px;
      background: #ebebeb;
      color: #999999;
      &.ok {
        background: #0754d3;
        color: #fff;
      }
    }
  }
  .tag {
    display: flex;
    font-size: 12px;
    margin: 10px 0;
    .left {
      background: #abaab9;
      color: #fff;
      padding: 2px 5px;
    }
    .right {
      padding: 2px 5px;
      color: #333;
      background: #f3f4f8;
    }
  }
  .time {
    font-size: 12px;
    color: #666;
    margin-bottom: 20px;
  }
  .line {
    margin: 0 -20px;
    height: 10px;
    background: #f8f8f8;
  }
  .science {
    font-size: 14px;
    font-weight: 600;
    margin: 20px 0 10px;
  }
  .material {
    display: flex;
    height: 110px;
    align-items: flex-end;
    overflow-x: auto;
    .item {
      width: 80px;
      height: 80px;
      display: inline-block;
      margin-right: 10px;
      box-shadow: 0px 4px 5px 1px rgb(0 42 59 / 11%);
      &:last-child {
        margin: 0;
      }
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
        overflow: hidden;
      }
      .mask {
        border-radius: 5px;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.3);
      }
      .ico {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: red;
        right: -8px;
        top: -8px;
        position: absolute;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: #b5b5b5;
        &.ok {
          background: #ffaf09;
          display: block;
          &::after {
            display: block;
            content: "";
            width: 10px;
            height: 5px;
            border-bottom: 2px solid #fff;
            border-left: 2px solid #fff;
            transform: translate(-50%, -50%) rotate(-45deg);
            visibility: visible;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -2px;
          }
        }
      }
    }
  }
  .info {
    font-size: 14px;
    text-align: center;
    color: #999999;
    margin-top: 20px;
  }
  .key {
    background: #f8f8f8;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 20px 0;
    .left {
      padding: 15px 20px;
      box-sizing: border-box;
      img {
        width: 60px;
        vertical-align: middle;
      }
    }
    .middle {
      flex: 1;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #000;
        margin-bottom: 5px;
      }
      .subTitle {
        font-size: 12px;
        color: #999;
      }
    }
    .right {
      img {
        vertical-align: middle;
        width: 70px;
      }
    }
  }
}
.piliang {
  position: fixed;
  right: 0;
  bottom: 100px;
  width: 70px;
  height: 70px;
  img {
    width: 100%;
  }
}
</style>
